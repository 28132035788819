<template>
    <div class="ExpensesTable Row-Wrap" :class="[{ 'is-empty': mExpenses.length === 0 }]">
        <!-- Header Row -->
        <Row
            v-if="mExpenses.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            class="Row-Header"
            @onClickColumn="handleHeaderClick"
        />

        <!-- Expense Rows -->
        <Row
            v-for="(expense, i) in mExpenses"
            :items="expense"
            :key="i"
            :index="i"
            :hasBadge="!!expense.color || hasBadge"
            @onClick="() => $emit('onClick', expense)"
        />

        <!-- Empty State -->
        <EmptyState v-if="mExpenses.length === 0" />
    </div>
</template>

<script>
import { format } from 'date-fns';
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState.vue';
import { euro } from '@/lib/helper';

export default {
    name: 'ExpensesTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        expenses: {
            type: Array,
            required: true,
        },
        hasBadge: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            sortState: {
                column: 'Verbucht am',
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                { name: 'Ausgabe', value: null },
                { name: 'Ausgabe (€)', value: null },
                { name: 'Verbucht am', value: null },
            ].map(header => ({
                ...header,
                name: `${header.name}${this.sortIndicator(header.name)}`,
            }));
        },
        mExpenses() {
            return this.sortExpenses([...this.formatExpenses(this.expenses)]);
        },
    },
    methods: {
        sortIndicator(columnName) {
            if (this.sortState.column === columnName) {
                return this.sortState.ascending ? ' ▲' : ' ▼';
            }
            return '';
        },
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending;
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true;
            }
            this.sortExpenses([...this.expenses]);
        },
        sortExpenses(expenses) {
            const { column, ascending } = this.sortState;
            return expenses.sort((a, b) => {
                const aEntry = a?.row?.find?.(item => item.name === column);
                const bEntry = b?.row?.find?.(item => item.name === column);
                const aVal = aEntry?.rawValue || aEntry?.value;
                const bVal = bEntry?.rawValue || bEntry?.value;

                if (ascending) {
                    return aVal > bVal ? 1 : -1;
                }
                return aVal < bVal ? 1 : -1;
            });
        },
        formatExpenses(expenses) {
            return expenses.map(expense => ({
                color: expense.color,
                row: [
                    { name: 'Ausgabe', value: expense?.service || '' },
                    {
                        name: 'Ausgabe (€)',
                        value: `${euro(expense.amount).format()}€`,
                        rawValue: expense?.amount,
                    },
                    {
                        name: 'Verbucht am',
                        value: expense.date ? format(new Date(expense.date), 'dd.MM.yyyy') : '--',
                        rawValue: expense?.date,
                    },
                ],
            }));
        },
    },
};
</script>

<style scoped lang="scss">
.ExpensesTable.Row-Wrap {
    width: 100%;
    top: 0;

    > * {
        min-width: 500px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }

    .Hint {
        font-size: 10px;
    }
}
</style>
